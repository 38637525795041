@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Book.woff2') format('woff2'),
      url('./fonts/CircularStd-Book.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Circular';
  font-style: normal;
  src: url('./fonts/CircularStd-Medium.woff2') format('woff2'),
      url('./fonts/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularStd-Bold.woff2') format('woff2'),
      url('./fonts/CircularStd-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'kanitmedium';
  src: url('./fonts/kanit-medium-webfont.woff2') format('woff2'),
    url('./fonts/kanit-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f7fa;
  font-family: 'Circular', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #111;
  font-size: .95rem;
}

body[class^="no-overflow"] {
  overflow: hidden;
}

body[class^="no-overflow"],
body[class^="no-overflow"] [data-for="app-header"],
body[class^="no-overflow"] [data-for="app-subheader"] {
  /* 17px is the typical default scrollbar-width; */
  width: calc(100% - 17px);
}

body[class^="no-overflow"],
body[class^="no-overflow"] [data-for="app-header"],
body[class^="no-overflow"] [data-for="app-subheader"] {
  /* 17px is the typical default scrollbar-width; */
  width: calc(100% - 17px);
}

html,
body,
#root {
  height: 100%;
}

ul {
  list-style: none;
}

a {
  color: #4372B0;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

a:focus:not(.disabled) {
  border-radius: 4px;
  -webkit-box-shadow: 0 0 0 1px #0064E5, 0 0 0 4px rgba(46, 137, 255, 0.4);
          box-shadow: 0 0 0 1px #0064E5, 0 0 0 4px rgba(46, 137, 255, 0.4);
  outline: none;
}

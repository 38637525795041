.Toastify__toast-container {
  -webkit-transition: top 300ms ease 0ms, right 300ms ease 0ms, bottom 300ms ease 0ms,
    left 300ms ease 0ms;
  -o-transition: top 300ms ease 0ms, right 300ms ease 0ms, bottom 300ms ease 0ms,
    left 300ms ease 0ms;
  transition: top 300ms ease 0ms, right 300ms ease 0ms, bottom 300ms ease 0ms,
    left 300ms ease 0ms;
}

.Toastify__toast-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Toastify__toast {
  width: 550px;
  margin-bottom: 16px;
  min-height: 48px;
  cursor: unset;
  border-radius: 3px;
  -webkit-box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2), 0px 5px 22px rgba(0, 0, 0, 0.12),
    0px 12px 17px rgba(0, 0, 0, 0.14);
          box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2), 0px 5px 22px rgba(0, 0, 0, 0.12),
    0px 12px 17px rgba(0, 0, 0, 0.14);
  padding: 0px;
  -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Toastify__toast-container--bottom-right {
  /* Bottom already has 16px margin, so we offset to 34px + 16px for 50px total */
  bottom: 34px;
  right: 50px;
}

.Toastify__toast--info {
  background: #2b3035;
}

.Toastify__toast--success {
  background: #09873c;
}

.Toastify__toast--error {
  background: #c62828;
}

.Toastify__toast--warning {
  background: #fbce53;
}

/* Animations for snackbar */
.Snackbar--slide-in {
  -webkit-animation-name: Snackbar--slide-in;
          animation-name: Snackbar--slide-in;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -o-transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.Snackbar--slide-out {
  -webkit-animation-name: Snackbar--slide-out;
          animation-name: Snackbar--slide-out;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -o-transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

@keyframes Snackbar--slide-in {
  0% {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes Snackbar--slide-in {
  0% {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }

  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes Snackbar--slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }
}

@-webkit-keyframes Snackbar--slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }
}

/**
  CUSTOMIZATIONS:

  1. Unmappable sites toast

*/

/* 1. Unmappable sites toast */
.Toastify__toast--info.project-unmappable-sites {
  width: 660px;
  float: right;
}

.Toastify__toast--info.project-unmappable-sites
  .Toastify__toast-body
  > div
  > div {
  max-width: inherit;
  margin: 0;
}

.Toastify__toast--custom {
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
}
